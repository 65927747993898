<template>
<div>
    <!-- <div class="global-search-box with-bg open">
        <div class="global-search-box-bg"></div>
        <div class="global-search-inner">
            <div class="global-search-row">
                <input type="text" class="global-search-input">
                <select class="global-search-select">
                    <option class="global-search-option" value="all" selected="">کلیه موارد</option>
                    <option class="global-search-option" value="markets.internal">بازار داخلی</option>
                    <option class="global-search-option" value="crypto">بازار ارزهای دیجیتال</option>
                    <option class="global-search-option" value="markets.stocks">بورس</option>
                    <option class="global-search-option" value="markets.global">بازار جهانی</option>
                    <option class="global-search-option" value="economics.country">اقتصاد کشورها</option>
                    <option class="global-search-option" value="economics.index">شاخص‌های اقتصادی</option>
                    <option class="global-search-option" value="economics.forecast">پیش بینی‌ها</option>
                    <option class="global-search-option" value="news">اخبار و مقالات</option>
                    <option class="global-search-option" value="solutions.farsi">راهنما و آموزش</option>
                </select>
            </div>
            <div class="global-search-result">
            </div>
        </div>
    </div> -->
    <!-- <div class="dashboard-widget-tabs">
        <div class="dashboard-widget-tabs-content padding-m">
            <h2>
                سرمایه گذاری
            </h2>
            <p>
                خلاصه توضیحات کوتاه درباره این بخش از داشبورد شبکه اطلاع رسانی ارز طلا و سکه
            </p>
            <div class="dashboard-widget-h-btns">
                <a href="" class="m-left m-top tgju-btn tgju-btn-light-orange"><i class="uil uil-layer-group"></i> عمومی</a>
                <a href="" class="m-left m-top tgju-btn tgju-btn-light-orange"><i class="uil uil-layer-group"></i> عمومی</a>
                <div class="dashboard-widget-help">
                    <i class="uil uil-question-circle"></i>
                </div>
            </div>
        </div>
        <div class="widget-tabs">
            <div class="widget-tabs-nav">
                <a href="" class="m-left m-top tgju-btn tgju-btn-light-orange"><i class="uil uil-layer-group"></i> عمومی</a>
                <a href="" class="m-left m-top tgju-btn tgju-btn-light-orange"><i class="uil uil uil-wallet"></i> دارایی‌ها</a>
                <a href="" class="m-left m-top tgju-btn tgju-btn-light-orange"><i class="uil uil-receipt-alt"></i> سوابق خرید</a>
                <div class="widget-select w-210 m-left m-top">
                    <v-select2 v-model="selected" :clearable="false" :options="['بازار طلا', 'بازار ارزهای دیجیتال']">
                        <i class="uil uil-list-ui-alt"></i>
                    </v-select2>
                </div>
            </div>
            <div class="widget-tabs-btn flex-btn">
                <a href="" class="tgju-btn m-top  min-w130 tgju-btn-success active"><i class="uil uil-shopping-cart-alt"></i> خرید</a>
                <a href="" class="m-right m-top  min-w130 tgju-btn tgju-btn-danger"><i class="uil uil-shopping-cart-alt"></i> فروش</a>
            </div>
        </div>
    </div>
    <div class="dashboard-widget-cards row tgju-widgets-row">
        <div class="col-12 col-md-6 col-lg-3 col-xl-3">
            <div class="tgju-widget light dashboard-widget-card border-danger">
                <div class="dashboard-widget-card-icon">
                    <i class="uil uil-file-alt"></i>
                </div>
                <div class="dashboard-widget-card-content">
                    <h2>دارایی کل</h2>
                    <span>1250000 تومان</span>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-3">
            <div class="tgju-widget light dashboard-widget-card border-warning">
                <div class="dashboard-widget-card-icon">
                    <i class="uil uil-chart-line"></i>
                </div>
                <div class="dashboard-widget-card-content">
                    <h2>سود در 24 ساعت گذشته</h2>
                    <span>475000 تومان</span>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-3">
            <div class="tgju-widget light dashboard-widget-card border-green">
                <div class="dashboard-widget-card-icon">
                    <i class="uil uil-file-info-alt"></i>
                </div>
                <div class="dashboard-widget-card-content">
                    <h2>مقادیر ریالی واریزی</h2>
                    <span>155000 تومان</span>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 col-xl-3">
            <div class="tgju-widget light dashboard-widget-card border-primary">
                <div class="dashboard-widget-card-icon">
                    <i class="uil uil-usd-circle"></i>
                </div>
                <div class="dashboard-widget-card-content">
                    <h2>مقادیر ارزی واریزی</h2>
                    <span>250 دلار </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row tgju-widgets-row">
        <div class="col-12 ">
            <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-dismissible alert-card alert-primary">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</div>
            <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-dismissible alert-card alert-success">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</div>
            <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-dismissible alert-info">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است </div>
            <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-dismissible alert-warning">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</div>
            <div role="alert" aria-live="polite" aria-atomic="true" class="alert alert-dismissible alert-danger"> لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</div>
        </div>
    </div>
    <a href="" class="m-left tgju-btn tgju-btn-primary"><i class="uil uil-shopping-cart-alt"></i> فروش</a>
    <a href="" class="m-left tgju-btn tgju-btn-success"><i class="uil uil-shopping-cart-alt"></i> خرید</a>
    <a href="" class="m-left tgju-btn tgju-btn-danger"><i class="uil uil-shopping-cart-alt"></i> فروش</a>
    <a href="" class="m-left tgju-btn tgju-btn-warning"><i class="uil uil-shopping-cart-alt"></i> خرید</a>
    <a href="" class="m-left tgju-btn tgju-btn-dark"><i class="uil uil-shopping-cart-alt"></i> خرید <div class="tgju-badge tgju-badge-danger">12</div></a>
    <a href="" class="m-left tgju-btn tgju-btn-light"><i class="uil uil-shopping-cart-alt"></i> خرید</a>
    <a href="" class="m-left tgju-btn tgju-btn-light-orange"><i class="uil uil-shopping-cart-alt"></i> خرید</a> -->



  <div class="text-center">
    <v-bottom-sheet
      v-model="sheet"
      inset
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="orange"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Inset
        </v-btn>
      </template>
      <v-sheet
        class="text-center"
        height="200px"
      >
        <v-btn
          class="mt-6"
          text
          color="error"
          @click="sheet = !sheet"
        >
          close
        </v-btn>
        <div class="my-3">
          This is a bottom sheet using the inset prop
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>







</div>


</template>

<style>
.footer-quickview {
    display: none !important;
}
</style>

<script>
// این یک ویو تستی برای تست المان های طراحی شده توسط اقای فرمانی می باشد
import vSelect2 from 'vue-select';
Vue.component('v-select2', vSelect2);

export default {
    name: 'WidgetsView',
    data: function () {
        return {
            sheet: false,
            selected: 'بازار طلا',
        }
    }
}
</script>
